import React, { useEffect, useState, useRef } from "react";
import { config } from "../../config/chat";
import ChatMessage from "../ChatMessage";
import { timeout } from "../../utils";
// import { Message } from "../../types";
import { config as numberConfig } from '../../config/numberPools';
import s from './style.module.scss';

type Message = any;
const Chat = () => {

    const [messages, setMessages] = useState(config.sections[0].messages);
    const [queuedMessages, setQueuedMessages] = useState(Array<Message>);
    const [messagesToRender, setMessagesToRender] = useState(Array<Message>);

    const [retreaverNumber, setRetreaverNumber] = useState('');
    const [retreaverHumanNumber, setRetreaverHumanNumber] = useState('');
    const [retreaverNumberGenerated, setRetreaverNumberGenerated] = useState(false);

    const [retreaverACANumber, setRetreaverACANumber] = useState('');
    const [retreaverACAHumanNumber, setRetreaverACAHumanNumber] = useState('');
    const [retreaverACANumberGenerated, setRetreaverACANumberGenerated] = useState(false);
    const [currentSection, setCurrentSection] = useState(0);
    const [callerZip, setCallerZip] = useState(0);
    const [callerZipLookedUp, setCallerZipLookedUp] = useState(false);

    const getIpLocation = async () => {
        const geoData = await fetch('https://api.ipstack.com/check?access_key=a011e62457615bb33b5bfa21afd5952a').then((res) => res.json()).catch((error) => { console.log('Failed to get geo data.') });
        setCallerZipLookedUp(true);
        if (geoData.zip)
            setCallerZip(geoData.zip)
    }

    useEffect(() => {
        if (callerZipLookedUp === false)
            getIpLocation();
    }, [])

    const determineRetreaverAffId = (affid: number) => {
        switch (affid) {
            case 2:
                return 'ec0ff953';
            case 3:
                return;
            case 4:
                return '46f16f00';
            case 5:
                return '70962830';
            case 6:
                return '6a758198';
            case 7:
                return '311977e5';
            case 8:
                return 'fb8ab40f';
            case 10073:
                return 'c55282c9';
        }
    }

    useEffect(() => {
        if ((window as any).Retreaver && !retreaverNumberGenerated && callerZipLookedUp && (window as any).rtkClickID) {
            new (window as any).Retreaver.Campaign({ campaign_key: numberConfig.retreaver.campaign_key }).request_number({}, hoistRetreaverNumber);
            setRetreaverNumberGenerated(true)

        }
        if ((window as any).Retreaver && !retreaverACANumberGenerated && callerZipLookedUp && (window as any).rtkClickID) {
            new (window as any).Retreaver.Campaign({ campaign_key: numberConfig.retreaverACA.campaign_key }).request_number({}, hoistRetreaverACANumber);
            setRetreaverACANumberGenerated(true)
        }
    })

    useEffect(() => {
        if (currentSection !== 0) {
            // (window as any).gtag('event', 'page_view', { 'page_title': config.sections[currentSection].page_name });
            console.log(config.sections[currentSection].page_name)
        }
    }, [currentSection])

    const hoistRetreaverNumber = (number: any) => {
        let query = window.location.href.split('?');
        if (query.length > 1) {
            let queryParams = new URLSearchParams(query[1]);
            number.add_tags({ 'redtrack_click_id': (window as any).rtkClickID })
            let affid = queryParams.get('affid');
            if (affid)
                number.add_tags({ 'publisher_id': determineRetreaverAffId(parseInt(affid)) })
        } else {
            number.add_tags({ 'redtrack_click_id': (window as any).rtkClickID })
        }
        number.add_tags({ 'caller_zip': callerZip })
        setRetreaverNumber(number.get().number_extension);
        setRetreaverHumanNumber(number.get().formatted_number);
    }

    const hoistRetreaverACANumber = (number: any) => {
        let query = window.location.href.split('?');
        if (query.length > 1) {
            let queryParams = new URLSearchParams(query[1]);
            number.add_tags({ 'redtrack_click_id': (window as any).rtkClickID })
            let affid = queryParams.get('affid');
            if (affid)
                number.add_tags({ 'publisher_id': determineRetreaverAffId(parseInt(affid)) })
        } else {
            number.add_tags({ 'redtrack_click_id': (window as any).rtkClickID })
        }
        number.add_tags({ 'caller_zip': callerZip })
        setRetreaverACANumber(number.get().number_extension);
        setRetreaverACAHumanNumber(number.get().formatted_number);
    }

    const renderQueuedMessages = async (messageQueue: any) => {
        if (messagesToRender.length !== 0 && !messageQueue[0].isUser)
            await timeout(config.typingDelay);
        setMessagesToRender([...messagesToRender, messageQueue[0]]);
        setQueuedMessages(queuedMessages.slice(1));
    }

    const processQueuedMessages = async (messageQueue: any) => {
        if (messagesToRender.length > 0 && !messageQueue[0].isUser)
            await timeout(messagesToRender[messagesToRender.length - 1].delay)
        renderQueuedMessages(queuedMessages)
    }

    useEffect(() => {
        if (messagesToRender.length + queuedMessages.length !== messages.length) {
            setQueuedMessages(messages.slice(messagesToRender.length));
        }
    }, [messages])

    useEffect(() => {
        if (queuedMessages.length > 0) {
            processQueuedMessages(queuedMessages)
        }
    }, [queuedMessages])

    const onSelection = (questionKey: string, value: any, nextSectionIndex: number, title: string) => {
        // debugger;
        sessionStorage.setItem(questionKey, value.toString());
        setMessagesToRender(messagesToRender.slice(0, -1))
        setMessages([...messages.slice(0, -1), { text: title, delay: 0, isUser: true }, ...config.sections[nextSectionIndex].messages])
        setCurrentSection(nextSectionIndex);
    }

    return (
        <div className={s.container}>
            {messagesToRender.map((message, i) =>
                <ChatMessage key={i} retreaverNumber={retreaverNumber} retreaverHumanNumber={retreaverHumanNumber} retreaverACANumber={retreaverACANumber} retreaverACAHumanNumber={retreaverACAHumanNumber} did={message.did} text={message.text} delay={message.delay} onSelection={onSelection} selections={message.selections} isUser={message.isUser} showAvatar={i === messagesToRender.length - 1 || messagesToRender[i + 1]?.isUser === true} />
            )}
        </div>
    )
}

export default Chat;