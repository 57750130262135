// import { DisclosureConfig } from "../types";

type DisclosureConfig = any;

export const config : DisclosureConfig = {
    "paragraphs": [
        "This website is not affiliated with the Affordable Cares Act program or any other government entity. The information provided on this website is for informational purposes only. It is not intended to be, nor does it constitute any kind of financial advice. Please seek advice from a qualified professional prior to making any financial decisions based on the information provided. This website acts as an independent digital media & advertising publisher. This webpage is formatted as an advertorial. An advertorial is an advertisement that is written in an editorial news format. PLEASE BE AWARE THAT THIS IS AN ADVERTISEMENT AND NOT AN ACTUAL NEWS ARTICLE, BLOG, OR CONSUMER PROTECTION UPDATE. This website MAY RECEIVE PAID COMPENSATION FOR CLICKS OR SALES PRODUCED FROM THE CONTENT FOUND ON THIS WEBPAGE. This compensation may affect which companies are displayed, the placement of advertisements, and their order of appearance. Any information, discounts, or price quotations listed may not be applicable in your location or if certain requirements are not met. Additionally, our advertisers may have additional qualification requirements.",
        "DISCLOSURE: The average monthly advanced premium tax credit across all enrollees was $527/month in 2023, with an average monthly premium of $123.69. Results vary based on a number of factors, including age, income, family size, health history, cost of available insurance coverage, where you live, and more. You are not eligible for the premium tax credit for coverage purchased outside the marketplace.",
        "Our goal is to provide exceptional service. One of our agents may reach out to you to discuss your order, ask for feedback, and/or see if you need any assistance with your products, services, or plans, at the phone number you provided regardless of your do-not-call list status. You may opt-out of further contact at any time by simply telling our customer service team that you would no longer like to be contacted. In the event that our team is unable to reach you by phone, they may send you a text message letting you know that we called. Both our text messages and phone calls may be sent or connected utilizing automated software. Carrier charges may apply. You may opt-out of any future contact via text message by replying anytime with \"STOP\"."
    ],
    "information_links": [
        {"title": "ACHI 2022", "href": "https://achi.net/newsroom/house-passes-bill-extending-aca-subsidies-through-2025-ensuring-continued-affordable-healthcare-insurance-for-millions/?"},
        {"title": "KFF 2022", "href": "https://www.kff.org/faqs/faqs-health-insurance-marketplace-and-the-aca/how-do-the-premium-tax-credits-work/"},
        {"title": "IRS 2022", "href": "https://www.irs.gov/affordable-care-act/individuals-and-families/the-premium-tax-credit-the-basics"},
        {"title": "Health Reform Basics 2022", "href": "https://www.healthreformbeyondthebasics.org/premium-tax-credits-answers-to-frequently-asked-questions/"},
        {"title": "CMS.gov", "href": "https://cms.gov"}
    ],
    "terms_links": [
        {"title": "Privacy Policy", "href": "" },
        {"title": "Terms and Conditions", "href": ""}
    ]
}