// import {
//     ChatConfig
// } from "../types";
import { redirect } from "../utils";

type ChatConfig = any;



export const config: ChatConfig = {
    botName: "Emily",
    botAvatar: "./img/avatar.webpg",
    userAvatar: "./img/user.png",
    typingDelay: 500,
    sections: [{
            page_name: "find_out_if_you_qualify",
            messages: [{
                    text: `Hi ${String.fromCodePoint(128079)}`,
                    delay: 1000,
                },
                {
                    text: "I'm Emily from Health Benefits",
                    delay: 1000,
                },
                {
                    text: `Want to find out if you or your family qualify for up to a $6,400 subsidy as a Healthcare Tax Credit? Tap Yes if so! ${String.fromCodePoint(128512)}`,
                    delay: 1000,
                },
                {
                    text: null,
                    delay: 0,
                    selections: [{
                        questionKey: "opt_in",
                        title: "Yes",
                        value: true,
                        nextSectionIndex: 1
                    }]
                }
            ],
        },
        {
            page_name: "income_less_than_60k",
            messages: [{
                    text: "Okay, let me ask you a couple quick questions.",
                    delay: 1000
                },
                {
                    text: "Do you make less than $60,000/year? Tap Yes or No.",
                    delay: 1000
                },
                {
                    delay: 0,
                    selections: [{
                            questionKey: "income_less_than_60k",
                            title: "Yes",
                            value: true,
                            nextSectionIndex: 2
                        },
                        {
                            questionKey: "income_less_than_60k",
                            title: "No",
                            value: false,
                            nextSectionIndex: 2
                        }
                    ]
                }
            ],

        },
        {
            page_name: "over_or_under_65",
            messages: [{
                    text: "Are you over or under 65?",
                    delay: 1000
                },
                {
                    delay: 0,
                    selections: [{
                            questionKey: "age",
                            title: "Over 65",
                            value: false,
                            nextSectionIndex: 4
                        },
                        {
                            questionKey: "age",
                            title: "Under 65",
                            value: "Medicaid",
                            nextSectionIndex: 3
                        },
                    ]
                }
            ],
        },
        {
            page_name: "medicare_or_medicaid",
            messages: [{
                    text: "Are you on medicaid or medicare?",
                    delay: 1000
                },
                {
                    delay: 0,
                    selections: [{
                            questionKey: "medicaid_or_medicare",
                            title: "Medicare",
                            value: "Medicare",
                            nextSectionIndex: 4
                        },{
                            questionKey: "medicaid_or_medicare",
                            title: "Medicaid",
                            value: "Medicaid",
                            nextSectionIndex: 9
                        },{
                            questionKey: "medicaid_or_medicare",
                            title: "No",
                            value: false,
                            nextSectionIndex: 5
                        },
               
                    ]
                }
            ],
        },
        {
            page_name: "medicare_a_b",
            messages: [{
                    text: "Do you have Medicare parts A and B?",
                    delay: 1000
                },
                {
                    delay: 0,
                    selections: [{
                            questionKey: "medicare_a_b",
                            title: "Yes",
                            value: "Yes",
                            nextSectionIndex: 6
                        },{
                            questionKey: "medicare_a_b",
                            title: "No",
                            value: false,
                            nextSectionIndex: 7
                        },
               
                    ]
                }
            ],
        },
        {
            page_name: "td_aca",
            messages: [
                {
                    text: `${String.fromCodePoint(127881)} Congratulations! ${String.fromCodePoint(127873)}`,
                    delay: 1000,
                },
                {
                    text: "You or your family may qualify for up to a $6,400 subsidy as a Healthcare Tax Credit",
                    delay: 1000
                },
                {
                    text: "Tap the number button below to call now to see if you qualify, the initial call only takes a few minutes.",
                    delay: 1000
                },
                {
                    delay: 1000,
                    did: {
                        tracker: "ringba"
                    }
                }
            ],
        },
        {
            page_name: "retreaver_medi",
            messages: [
                {
                    text: `Great News! ${String.fromCodePoint(127881)}`,
                    delay: 1000,
                },
                {
                    text: "Although you aren’t qualified for a Health Subsidy, agencies are now offering Medicare Advantage coverage in your area.",
                    delay: 1000
                },
                {
                    text: "Call the number below to learn more about what you could qualify for:",
                    delay: 1000
                },
                {
                    delay: 0,
                    did: {
                        tracker: "ringba"
                    }
                }
            ],
        },
        {
            page_name: "acp",
            messages: [
                {
                    text: `Great News! ${String.fromCodePoint(127881)}`,
                    delay: 1000,
                },
                {
                    text: "Although you aren’t qualified for a Health Subsidy, agencies are now offering free cellular plans and phones.",
                    delay: 1000
                },
                {
                    text: "Click the link below to secure your free phone and cellular data plan now!",
                    delay: 1000
                },
                {
                    delay: 0,
                    selections: [{
                        title: "Get My Free Phone!",
                        onClick: () => redirect('https://www.zq3np.com/54MZS8/M2GJW/')
                    }
                ]
                }
            ],
        },
        {
            page_name: "DQ",
            messages: [
                {
                    text: "Sorry, but the questions you answered have helped us determine that we are not able to help you at this time.",
                    delay: 1500
                },
                {
                    text: `Have a nice day! ${String.fromCodePoint(128512)}`,
                    delay: 1500
                },
            ],
        },
        {
            page_name: "credit_repair",
            messages: [
                {
                    text: `Great News! ${String.fromCodePoint(127881)}`,
                    delay: 1000,
                },
                {
                    text: "Although you don't qualify for the health insurance subsidy, this new program can allow you increase your credit score quickly.",
                    delay: 1000
                },
                {
                    delay: 0,
                    selections: [{
                        title: "Increase my credit score!",
                        onClick: () => redirect('https://www.zq3np.com/54MZS8/9B9DM/')
                    }
                ]
                }
            ],
        },
    ]
}