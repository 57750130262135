import React from "react";
import { config } from "../../config/disclosures";
import s from './style.module.scss'


const Disclosures = () =>{

    const linkClick = (e: any, href: string) => {
        e.preventDefault();
        window.open(href)
    }

    return(
        <div className={s.container}>
            {config.paragraphs.map((p:any, i:any)=><p key={i} className={s.paragraph}>{p}</p>)}
            <div className={s.informationLinks}>
                {config.information_links.map((link:any, i:any)=>{
                    return i !== config.information_links.length -1 ? (<span key={i} className={s.divider}><a className={s.link} href={link.href}>{link.title}</a> | </span>) : <a key={i} className={s.link} href={link.href}>{link.title}</a>
                })}
            </div>
            <div className={s.termsLinks}>
            {config.terms_links.map((link:any, i:any)=>{
                    return i !== config.terms_links.length -1 ? (<span key={i} className={s.divider}><a className={s.link} href={link.href} onClick={(e)=>linkClick(e, link.href)}>{link.title}</a> | </span>) : <a key={i} className={s.link} href={link.href}>{link.title}</a>
                })}
            </div>
        </div>
    )
}

export default Disclosures;