// import { NumberPoolsConfig } from "../types";

type NumberPoolsConfig = any;

export const config : NumberPoolsConfig = {
    retreaver: {
        campaign_key: "bd376b8fa8c3c1efae7dfc8e19bb179e"
    },
    retreaverACA: {
        campaign_key: "249731b64ba9c84240b7934560eba899"
    },
    trackdrive: {
        offer_token: "754a9936801e8ff256925b58a748521e",
        default_number: "+18449340565",
        default_human_number: "(844) 934 0565"
    },
    ringba: {
        default_number: "+18339170077",
        default_human_number: "(833) 917-0077",
        camapign_key: "CA0e36dcea68db4eaea6a11dde629a0bce"
    }

    // <script src=//b-js.ringba.com/CA0e36dcea68db4eaea6a11dde629a0bce async></script> 
}