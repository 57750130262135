// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_container__GCvyz {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 5vw;
  padding-right: 5vw;
}

.style_contentContainer__GQ-PJ {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/style.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,iBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AACJ","sourcesContent":[".container{\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    padding-left:5vw;\r\n    padding-right:5vw;\r\n}\r\n\r\n.contentContainer{\r\n    min-height: 100vh;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__GCvyz`,
	"contentContainer": `style_contentContainer__GQ-PJ`
};
export default ___CSS_LOADER_EXPORT___;
