import React from "react";
import { config } from "../../config/header";
import {config as chatConfig} from "../../config/chat";
import s from './style.module.scss'

const Header = () => {

    const date = new Date();
    const options : Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' }
    return(
        <div className={s.container}>
            <h3>Offices Close Today at 8:00 PM EST</h3>
            <h1>{config.headline}</h1>
            <div className={s.online}>
                <p className={s.pulsatingCircle}></p><p className={s.botName}> {chatConfig.botName} is Online.</p>
            </div>
   
        </div>
    )

}

export default Header;