// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_container__PLsXn {
  background: rgb(243, 244, 246);
  border-top: 1px solid rgb(229, 231, 235);
  padding-left: 5vw;
  padding-right: 5vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5vh;
}

.style_paragraph__kVaWa {
  padding-top: 2vh;
  padding-bottom: 2vh;
  text-align: center;
  font-size: 0.7rem;
  max-width: 1000px;
}

.style_link__EbJgj {
  text-decoration: none;
  font-size: 0.8rem;
  color: rgb(96, 165, 250);
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.style_informationLinks__LhbV8 {
  margin-bottom: 2.5vh;
  margin-top: 2.5vh;
}

.style_termsLinks__as5iz {
  margin-bottom: 5vh;
}

.style_divider__dCqtJ {
  color: rgb(96, 165, 250);
}`, "",{"version":3,"sources":["webpack://./src/components/Disclosures/style.module.scss"],"names":[],"mappings":"AAAA;EACI,8BAAA;EACA,wCAAA;EACA,iBAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;AACJ;;AAEA;EACI,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,iBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,qBAAA;EACA,iBAAA;EACA,wBAAA;EACA,4NAAA;AACJ;;AAEA;EACI,oBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,wBAAA;AACJ","sourcesContent":[".container{\r\n    background: rgb(243, 244, 246);\r\n    border-top: 1px solid rgb(229, 231, 235);\r\n    padding-left: 5vw;\r\n    padding-right: 5vw;\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n    margin-top:5vh;\r\n}\r\n\r\n.paragraph{\r\n    padding-top:2vh;\r\n    padding-bottom:2vh;\r\n    text-align: center;\r\n    font-size: .7rem;\r\n    max-width: 1000px;\r\n}\r\n\r\n.link{\r\n    text-decoration: none;\r\n    font-size:.8rem;\r\n    color: rgb(96, 165, 250);\r\n    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, \"Helvetica Neue\", Arial, \"Noto Sans\", sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\"\r\n}\r\n\r\n.informationLinks{\r\n    margin-bottom: 2.5vh;\r\n    margin-top:2.5vh\r\n}\r\n\r\n.termsLinks{\r\n    margin-bottom: 5vh;\r\n}\r\n\r\n.divider{\r\n    color: rgb(96, 165, 250);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__PLsXn`,
	"paragraph": `style_paragraph__kVaWa`,
	"link": `style_link__EbJgj`,
	"informationLinks": `style_informationLinks__LhbV8`,
	"termsLinks": `style_termsLinks__as5iz`,
	"divider": `style_divider__dCqtJ`
};
export default ___CSS_LOADER_EXPORT___;
